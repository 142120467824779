// app-discount-list {
//   display: flex;
//   flex-direction: column;
//   width: 100vw;
//   height: 100%;

//   #product-list-desktop {
//     width: 100%;
//   }

//   .header {
//     width: 100%;
//     padding: 0 1rem;
//     display: flex;
//     flex-direction: row;
//     align-content: center;
//     align-items: center;

//     .product-modify-content-return-button {
//       display: flex;
//       flex-direction: row;
//       align-content: center;
//       align-items: center;

//       span {
//         margin-left: 1rem;
//       }
//     }
//   }

//   .promotion-title {
//     width: 100%;
//     padding: 0 1rem;
//   }

//   .close-alert {
//     background: var(--background-alert);
//     border-radius: 10px;
//     padding: 0.5rem 1.5rem;
//     margin: 0.5rem;
//     width: fit-content;
//   }
// }

.p-dialog-discount-product {
  .p-dialog-header {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: var(--header__padding-height) var(--header__padding-width);
    background: transparent;

    @media only screen and (max-width: 879px) {
      display: none;
    }
  }

  div {
    div {
      .p-dialog-content {
        padding: 0;
      }
    }
  }
}
