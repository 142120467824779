$md-palette-navy: (
  50: #e1e3e6,
  100: #b4b9c1,
  200: #828b97,
  300: #505c6d,
  400: #2b394e,
  500: #05162f,
  600: #04132a,
  700: #041023,
  800: #030c1d,
  900: #010612,
  A100: #536dff,
  A200: #2041ff,
  A400: #0023ec,
  A700: #001fd3,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-palette-orange: (
  50: #fef7e1,
  100: #fcecb3,
  200: #fadf81,
  300: #f7d24e,
  400: #f6c928,
  500: #f4bf02,
  600: #f3b902,
  700: #f1b101,
  800: #efa901,
  900: #ec9b01,
  A100: #ffffff,
  A200: #fff3e0,
  A400: #ffdfad,
  A700: #ffd593,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

@use '~@angular/material' as mat;
@use 'palette-helper';

@import '~@angular/material/theming';

@include mat.core();

$angular-background: mat.define-palette($md-palette-navy, 500, 100, 900);
$angular-primary: mat.define-palette($md-palette-orange, 500, 100, 900);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$angular-warn: mat.define-palette(mat.$red-palette);

$angular-default-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
      background: $angular-background,
    ),
  )
);

@include mat.all-component-themes($angular-default-theme);

$angular-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
      background: $angular-background,
    ),
  )
);

.light-theme {
  @include mat.all-component-colors($angular-light-theme);
}

$app-active: mat.define-palette($mat-yellow, 800);

$spacer: 1rem;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
  6: (
    $spacer * 4,
  ),
  7: (
    $spacer * 5,
  ),
  8: (
    $spacer * 6.25,
  ),
  9: (
    $spacer * 7.5,
  ),
  10: (
    $spacer * 9.375,
  ),
);

@each $size, $length in $spacers {
  .mr-#{$size} {
    margin-left: initial !important;
    margin-right: #{$length} !important;
  }

  .ml-#{$size} {
    margin-right: initial !important;
    margin-left: #{$length} !important;
  }

  .pr-#{$size} {
    padding-left: initial !important;
    padding-right: #{$length} !important;
  }

  .pl-#{$size} {
    padding-right: initial !important;
    padding-left: #{$length} !important;
  }
}

/* Themes */
:root {
  /* Icons */
  --hamburger-button-src: url('./../assets/icons/tapp-order-hamburger-button.png');
  --toolbar-search-icon: url('./../assets/icons/search-icon-32x32.png');
  --article-card-x-button: url('./../assets/icons/tapp-order-x-button.png');
  --order-success-animation-img: url('./../assets/images/order-success-animation.png');
  --arrow-right-src: url('./../assets/icons/arrow-right.png');
  --close-src: url('./../assets/icons/close.png');
  --plus-src: url('./../assets/icons/plus.png');
  --basket-src: url('./../assets/icons/basket.png');
  --headphones-src: url('./../assets/icons/headphones.png');
  --check-src: url('./../assets/icons/pi-check.png');

  /* Colors */
  //--main-background-color: #f3f3f3;
  --main-background-color: #f8f9fa;
  --main-font-color: #383f41;
  /* Nagłówek */
  --header-background-color: #ffffff;
  --toolbar-menu-item-color: #383f41;
  --toolbar-menu-item-active-border-bottom-color: #e3001b;
  --toolbar-menu-item-border-bottom-color: transparent;
  --navbar-after-background-color: #e9e9e9;
  --group-bar-label-color: #383f41;
  /* Karta produktu */
  --article-card-expandable-background-color: #ffffff;
  --article-card-expandable-expanded-background-color: #ffffff;
  --article-card-expandable-title-color: #383f41;
  --article-card-expandable-description-color: #909193;
  --article-card-expandable-price-color: #383f41;
  --article-card-expandable-add-button-color: #ffffff;
  --article-card-expandable-add-button-background-color: #e3001b;
  --article-card-expandable-quantity-modifier-color: #383f41;
  --article-card-expandable-quantity-modifier-background-color: #f9f9f9;
  --article-card-expandable-description-modifier-color: #383f41;
  /* Modyfikatory */
  --modifier-accordion-header-background-color: #ededed;
  --modifier-accordion-header-label-color: #383f41;
  --modifier-accordion-header-description-color: #909193;
  --modifier-accordion-body-background-color: #ffffff;
  --modifier-accordion-body-item-color: #383f41;
  --modifier-accordion-body-item-border-bottom-color: #828b97;
  --modifier-price-color: #626262;
  --modify-quantity-button-background-color: #f3f3f3;
  --modify-quantity-button-color: #383f41;
  --modfier-radio-button-before-background: #ffffff;
  --modifier-checkmark-background-color: #ffffff;
  /* Przycisk bottom-button */
  --bottom-button-background-color: #bdcd00;
  --bottom-button-color: #ffffff;
  /* Podsumowanie zamówienia */
  --your-order-header-color: #383f41;
  --your-order-summary-items-count-color: #808080;
  --your-order-summary-table-id-color: #383f41;
  --your-order-summary-price-color: #383f41;
  /* Menu Hamburger */
  --full-screen-modal-background-color: #ffffff;
  --hamburger-button-menu-primary-text: #1c1c1d;
  /* Footer */
  --footer-credits-color: #aaaaaa;
  /* Ekran podsumowania po zapłaceniu */
  --order-success-background-color: #ffffff;
  --order-success-primary-text: #383f41;
  --order-success-secondary-text: #909193;
  --order-success-line-color: #e8e2e2;
  --order-success-summary-item-description-color: #383f41;
  --order-success-summary-item-bottom-text: #383f41;
  /*T-PIZZA*/
  --tpizza-red-01: #e3001b;
  --tpizza-red-02: #b3001b;
  --tpizza-red-03: #ff9999;
  --tpizza-grey-01: #383f41;
  --tpizza-grey-02: #7d7d7d;
  --tpizza-grey-03: #ced4da;
  --tpizza-grey-04: #ededed;
  --tpizza-grey-05: #f8f9fa;
  --tpizza-grey-06: #cacaca;
  --tpizza-grey-07: #242424;
  --tpizza-white-01: #ffffff;
  --tpizza-green-01: #00af85;
  --tpizza-green-02: #b8ce09;
  --tpizza-green-03: #a8ce09;
  --tpizza-blue-01: #149ee9;
  --payments-radiobutton-background: #ffffff;

  /*icons filter*/
  --icons-filter: invert(15%) sepia(89%) saturate(5179%) hue-rotate(351deg) brightness(88%) contrast(104%);
}
