app-article-card {
  .card-article {
    img {
      //box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
      margin-right: 2rem;
      width: 64px;
      height: 64px;
      border-radius: var(--border-radius-input);
      aspect-ratio: 1/1;
      object-fit: cover;
    }
    .quantity-picker {
      color: $white;
      background-color: #222c3a;
      font-size: 14px;
      line-height: 119%;
      border-radius: 0 0 8px 8px;

      .quantity {
        display: flex;
        align-items: center;
        text-align: center;
      }
      button {
        font-size: x-large;
      }
    }

    .quantity {
      margin: auto 1rem;
      margin-left: 0;
    }
  }
}
