/**
 * Efficiently adapts to varying screen min widths by dynamically adjusting specific CSS variables.
 *
 * Refer to the accompanying .md file for detailed documentation.
 */
@mixin set-var-across-min-width($var-name, $breakpoints) {
  @each $breakpoint, $value in $breakpoints {
    @media screen and (min-width: $breakpoint) {
      #{$var-name}: #{$value};
    }
  }
}
