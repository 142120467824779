.qr-scan-header {
  position: relative;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  width: 80%;
  text-align: center;
  margin: 54px auto 16px;

  p {
    color: var(--font-primary);
  }

  .qr-code {
    background: url('/assets/images/qr-code.svg') center center;
    height: 164px;
    width: 164px;
    min-height: 164px;
    min-width: 164px;
    margin: 2rem auto;
  }
}

.qr-scan-area {
  position: relative;
  zxing-scanner {
    max-width: 100%;
  }

  .qr-area {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 175px);
    top: 50px;
    width: 100%;
    min-height: 250px;

    .area {
      height: 250px;
      width: 250px;
      border: 2px dotted red;
    }
  }
}
