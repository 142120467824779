app-product-modifiers {
  .modal-group-wrapper {
    width: 100%;
    padding: 0 0 20px 0;
    margin: 0;
    border-bottom: 1px solid var(--border-color-secondary-1);

    @media only screen and (max-width: 768px) {
      border-bottom: 1px solid var(--background-primary);
      border-top-color: var(--background-primary) !important;
    }

    &.last {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .modal-group-header {
    display: flex;
    align-items: center;
    background: transparent;
    flex-direction: column;
    padding: 0;
    height: auto;

    &-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      width: 100%;
      text-transform: uppercase;
    }

    &-requirements {
      width: 100%;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin-top: 3px;
      color: var(--font-placeholder);
    }
  }

  .products-wrapper {
    @media only screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }
}
