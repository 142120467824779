@font-face {
  font-family: 'Icon';
  src: url('/assets/fonts/Icon/Icon.woff2');
  src: url('/assets/fonts/Icon/Icon.woff2') format('woff2'), url('/assets/fonts/Icon/Icon.woff') format('woff'),
    url('/assets/fonts/Icon/Icon.ttf') format('truetype'), url('/assets/fonts/Icon/Icon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Icon';
    src: url('/assets/fonts/Icon/Icon.svg#Flaticon') format('svg');
  }
}

.ic {
  font-family: 'Icon';
  font-size: 24px;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.ic-plus:before {
  content: '\f100';
}

.ic-location:before {
  content: '\f101';
}

.ic-promo:before {
  content: '\f102';
}

.ic-arrow-right:before {
  content: '\f103';
}

.ic-user:before {
  content: '\f104';
}

.ic-list:before {
  content: '\f105';
}

.ic-address:before {
  content: '\f106';
}

.ic-app:before {
  content: '\f107';
}

.ic-back:before {
  content: '\f108';
}

.ic-phone:before {
  content: '\f109';
}

.ic-send-mail:before {
  content: '\f110';
}

.ic-close:before {
  content: '\f111';
}

.ic-delete:before {
  content: '\f112';
}

.ic-take-away:before {
  content: '\f113';
}

.ic-delivery:before {
  content: '\f114';
}

.ic-logout:before {
  content: '\f115';
}

.ic-order-history:before {
  content: '\f116';
}

.ic-arrow-right-2:before {
  content: '\f117';
}

.ic-check:before {
  content: '\f118';
}

.ic-cash:before {
  content: '\f119';
}

.ic-promo-2:before {
  content: '\f120';
}

.ic-food-status:before {
  content: '\f121';
}

.ic-pizzeria:before {
  content: '\f122';
}

.ic-pizza-time:before {
  content: '\f123';
}

.ic-menu:before {
  content: '\f124';
}

.ic-chevron-right:before {
  content: '\f125';
}

.ic-chevron-left {
  transform: rotate(180deg);

  &:before {
    content: '\f125';
  }
}

.ic-trashbin:before {
  content: '\f126';
}

.ic-basket:before {
  content: '\f127';
}

.ic-arrow-down:before {
  content: '\f128';
}

.ic-edit:before {
  content: '\f129';
}

.ic-filters:before {
  content: '\f130';
}

.ic-attributes:before {
  content: '\f131';
}
