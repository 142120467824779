@use '../../../../scss/mixins.scss';

app-product-pizza-card {
  margin-bottom: 2.5rem;
  padding-right: 20px !important;
  padding-left: 20px !important;

  .pizza-card-container {
    background: var(--background-secondary);
    box-shadow: 0px 26px 37px rgba(38, 40, 43, 0.06);
    border-radius: var(--border-radius-card);
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .pizza-card-image {
    padding-left: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 170px;

    img {
      margin-left: -37%;
      max-height: 98%;
      object-fit: contain;
      height: 100%;
    }

    @media screen and (max-width: 1524px) and (min-width: 1430px) {
      img {
        margin-left: -40%;
      }
    }

    @media screen and (max-width: 1429.9px) and (min-width: 1360px) {
      img {
        margin-left: -50%;
      }
    }

    @media screen and (max-width: 1359.9px) and (min-width: 1305px) {
      img {
        margin-left: -60%;
      }
    }

    @media screen and (max-width: 1304.9px) and (min-width: 1264px) {
      img {
        margin-left: -65%;
      }
    }

    @media screen and (max-width: 1263.9px) and (min-width: 1240px) {
      img {
        margin-left: -70%;
      }
    }

    @media screen and (max-width: 1239.9px) and (min-width: 1220px) {
      img {
        margin-left: -75%;
      }
    }

    @media screen and (max-width: 1219.9px) and (min-width: 1200px) {
      img {
        margin-left: -80%;
      }
    }
  }

  p {
    margin-bottom: 0.5rem;
  }

  .pizza-card-context {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pizza-card-context-container {
      display: flex;
      flex-direction: column;

      .pizza-card-context-title {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @include mixins.text-title(var(--app-product-title-font-size));
        color: var(--font-primary);
      }

      .pizza-card-context-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: var(--font-secondary);
        max-width: 20rem;
      }
    }
  }

  .pizza-card-buttons-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding-bottom: 1rem;

    &__price {
      margin: 0 auto 0 0;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: var(--font-primary);
    }

    .add-button {
      background: var(--primary);
      border-radius: var(--border-radius-input);
      height: 32px;
      width: 115px;
      color: var(--font-white);
      border: none;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__text {
        width: 100%;
        position: relative;
        margin-right: 4px;

        .plus {
          position: absolute;
          top: 49.6%;
          right: 9px;
          transform: translateY(-50%);
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }
}

.selected-products {
  width: 100%;
  margin: 40px 15px 25px;
  display: block;
  flex-direction: column;
  border-bottom: 3px solid var(--border-color-secondary-1);

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: var(--font-primary);
    text-transform: uppercase;
  }

  &__products {
    width: 100%;
    margin-top: 17px;
    display: flex;
    overflow: auto;
    /*padding-bottom: 22px;*/
    scrollbar-color: var(--primary) #f1f2f3;

    &::-webkit-scrollbar {
      background: #f1f2f3;
      border-radius: 10px;
      height: 6px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--primary);
    }

    &__item {
      display: flex;
      flex-direction: column;
      min-width: 350px;
      /*max-height: 70px;*/

      &__title {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: var(--font-primary);
      }
      &__description {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        /*color: #6D7071;*/
        color: var(--font-primary);
        margin-top: 0px;
        margin-bottom: 0px;
        box-sizing: border-box;
        padding-right: 10px;
      }
      &__size {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        color: var(--font-secondary);
        margin-bottom: 0px !important;
      }

      &__modifiers {
        margin: 0.5rem 0;

        &__modifier {
          margin: 0;
          @include mixins.text-second(0.75rem);

          &--added {
            color: var(--green);
          }
          &--removed {
            color: var(--red);
          }
        }
      }
    }
  }
}
