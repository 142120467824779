app-products-page {
  .container {
    max-height: 100vh;

    .group-articles {
      .card-article {
        background-color: var(--background-secondary) !important;
      }
    }
  }
}

app-products-page .content-wrapper {
  width: 100%;
  max-width: 100% !important;
}
