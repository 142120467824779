.ng-submitted {
  .ng-invalid > input {
    border-color: var(--error-color);
  }
}

.invalid-feedback {
  display: block;
  position: absolute;
  background-color: var(--error-color);
  color: var(--font-white);
  border-radius: var(--border-radius-input);
  font-size: 0.7rem;
  padding: 2px 6px 2px 6px;
  z-index: 3;
}

.form-group {
  display: inline-block;
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;

  label {
    color: var(--font-primary);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }

  [formControlName] {
    position: relative;
    z-index: 2;
    color: var(--font-primary) !important;
    opacity: 1;
  }
  [formControlName] ~ label {
    position: absolute;
    cursor: text;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    color: var(--font-primary);
  }
  ::-webkit-inpu t-placeholder {
    color: var(--font-placeholder);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: var(--font-family);
  }

  :-ms-input-placeholder {
    color: var(--font-placeholder);
    ffont-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: var(--font-family);
  }

  ::placeholder {
    color: var(--font-placeholder);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: var(--font-family);
  }
  textarea[formControlName] ~ label {
    top: 20px;
  }
  [formControlName] ~ label,
  [formControlName] {
    transition: all 0.2s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    touch-action: manipulation;
  }
  [formControlName]:focus ~ label,
  [formControlName]:not(:placeholder-shown) ~ label {
    opacity: 1;
    top: 0;
    left: 0;
    transform: scale(0.8) translateY(calc(-100% - 1px));
    transform-origin: top left;
  }
}

.checkbox {
  cursor: pointer;

  .p-checkbox {
    order: 2;
    width: 16px;
    height: 16px;
    margin: 0;

    .p-checkbox-box {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      border: 1px solid var(--background-grey-5);

      &.p-highlight {
        border-color: var(--secondary) !important;
        background: var(--secondary) !important;
      }

      &:not(.p-disabled):not(.p-highlight):hover {
        border-color: var(--secondary);
      }

      .p-checkbox-icon {
        position: relative;

        &::before {
          font-size: 10px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .p-checkbox-label {
    cursor: pointer;
    width: calc(100% - (16px + 8px));
    order: 1;
    margin: 0;
    font-size: 0.6rem;
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.p-field-radiobutton {
  margin-right: 0;
  label {
    margin: 0;
    background-color: var(--background-secondary);
    padding: 18px 12px;
    cursor: pointer;
    .label {
    }
  }
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  .radio {
    width: 16px;
    height: 14px;
    margin-right: 18px;

    .p-radiobutton {
      width: 16px;
      height: 16px;
    }
    .p-radiobutton-box {
      width: 16px;
      height: 16px;

      &.p-highlight {
        border-color: var(--border-color-primary) !important;
      }
      &:not(.p-disabled):not(.p-highlight):hover {
        border-color: var(--primary);
      }
    }
    .p-radiobutton-icon {
      background-color: var(--primary) !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--background-secondary) inset !important;
}
