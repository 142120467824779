@use '../../scss/mixins';

@import 'icons';
@import '../theme';

@import 'src/app/shared/payment/payment.component';
@import './forms';
@import './buttons';

@import '../../scss/style';
//@import "src/app/shared/form-errors/";

$card-background: #27303e;

$app-max-width: 540px;
//colors
$white: #ffffff;
$light-gray: #d8d8d8;
$gray: #c3c9d1;
$orange: #f4bf02;

$color-title: #ffffff;
$color-subtitle: #b5c5dc;
$color-content: #b4c4dc;
$color-group-label: #383f41;

* {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
}

html {
  scroll-behavior: smooth;
  scroll-margin-top: 2rem;
}

html,
body {
  background-color: var(--background-primary);
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

body {
  overflow-y: scroll;
}

p-dynamicdialog {
  div {
    // position: relative !important;
    z-index: 9999 !important;
  }
}

button:focus {
  outline: none !important;
}

.landscape {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 2rem;
  color: #ffffff;
  text-align: center;
}

.watermark {
  background: url('/assets/images/D.svg') center center;
  background-repeat: no-repeat;
  min-height: 600px;
}

.container {
  margin-bottom: 2rem;
}

.mat-app-background {
  background-color: var(--background-primary);
  color: var(--font-primary);
}

.cdk-overlay-container {
  z-index: 9999;
}

.cursor-pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

/*.modal-content {
    background-color: #27303e!important;
    border-radius: 8px!important;

    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    .card {
        box-shadow: none!important;
        border: none!important;
    }
}*/

.full-screen-modal {
  height: calc(100 * var(--vh, 1vh)) !important;
  width: 100vw !important;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 !important;
  background-color: var(--background-secondary) !important;

  .modal-content {
    border-radius: 0px !important;
    background-color: transparent;
    border: 0px;
  }
}

.modal-backdrop {
  transition: opacity 0.05s linear;
}

@keyframes anim-open {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes anim-close {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-200px);
  }
}

@media only screen and (max-width: 600px) {
  // #dev #dev.scrollbar #dev.webkit
  ::-webkit-scrollbar {
    display: none;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.article-card-modal {
  height: 100vh !important;
  height: calc(100 * var(--vh, 1vh)) !important;
  width: 100vw !important;
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  top: unset;
  margin: 0px auto !important;
  background-color: transparent !important;
  transition: all 400ms ease;

  .modal-content {
    border-radius: 12px 12px 0px 0px !important;
    background-color: var(--article-card-expandable-expanded-background-color);
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.02);
    border: 0px;
    max-height: 90vh;
    max-height: calc(90 * var(--vh, 1vh)) !important;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed !important;
    bottom: 0px;
  }
}

h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 119%;
}

.group-label {
  color: var(--group-bar-label-color);
  //text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.group-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: var(--group-bar-label-color);
  text-transform: capitalize;
  padding: 5rem 0 1rem;
  margin: 0;

  //align-items: baseline;

  &-label {
    width: 100%;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    position: relative;
  }
}

.mat-card {
  margin-bottom: 6px;
  background-color: #ffffff;
  border-radius: 3px !important;
  box-shadow: none !important;
  border-color: transparent !important;

  mat-card-title-group {
    min-height: 80px;
  }

  &-header {
    justify-content: center;
    font-size: 21px;
    line-height: 119%;

    &-text {
      width: 100%;
      margin: 0 !important;
      text-align: center;
    }
  }

  &-title {
    align-items: center;
    color: $color-title;
  }

  &-subtitle {
    font-size: 12px;
    line-height: 130%;
    color: $color-subtitle;
  }

  &-content {
    font-size: 14px;
    line-height: 130%;
    color: $color-content;
  }

  &.card-group {
    padding: 0 16px;
    color: var(--font-white) !important;

    .mat-card-title-group {
      min-height: 40px;

      img {
        width: 40px;
        height: 40px;
        border-radius: var(--border-radius-input);
        aspect-ratio: 1/1;
        object-fit: cover;
      }

      .mat-card-title {
        margin-bottom: 0;

        font-weight: normal;
        font-size: 1rem;
        line-height: 119%;

        color: var(--font-white) !important;
        font-weight: 500;
      }
    }
  }

  &.card-details {
    img {
      min-height: 358px;
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    button {
      &.close {
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
        background-color: #f4bf02;
        color: black;
        text-shadow: none;
        opacity: 0.8;
      }

      &.special-btn {
        width: 100%;
        color: #b6c2d2;
        background: #2e3744;
        margin-bottom: 1rem;
      }
    }

    h5.group-label + p {
      font-weight: normal;
      font-size: 14px;
      line-height: 132%;
    }

    .title {
      align-items: center;

      &-price {
        text-align: right;
      }
    }
  }

  .product-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: var(--border-radius-input);
    padding: 0;
    width: 100%;
    color: var(--font-primary);
    font-weight: 400;

    &.product-list-detail,
    .product-list-detail {
      flex: 1 1 0;
      padding-right: 1rem;

      .product-name {
        font-size: 1rem;
        line-height: 19px;
        color: var(--font-primary) !important;

        &-small {
          font-size: 14px;
          margin-top: 2px;
        }
      }

      .product-description {
        color: #909193;
        font-size: 12px;
        line-height: 16px;
        position: absolute;
        bottom: 1rem;
        font-weight: 400;
      }
    }

    .product-list-action {
      display: flex;
      flex-direction: column;

      .product-price {
        font-size: 1rem;
        align-self: flex-end;
      }

      button {
        width: 29px;
        height: 29px;
        padding: 0;
        min-width: 29px;
        min-height: 29px;
        line-height: 29px;
        font-size: x-large;
        align-self: flex-end;
        margin-top: 0.5rem;
        background-color: #ffb800 !important;
        color: #ffffff !important;
        border-radius: 3px !important;
        box-shadow: none !important;
      }

      .product-in-cert {
        align-self: flex-end;
        color: $orange;
      }

      mat-icon {
        align-self: end;
      }
    }
  }

  .product-list-subitem {
    border-radius: 0;

    &:first-of-type {
      border-top: 1px solid #ffffff1f;
    }
  }
}

.submit-btn {
  button {
    width: 100%;
    height: 50px;
    margin-top: 4px;
  }
}

.action-button {
  width: 100%;
  padding: 0.5rem !important;

  font-size: 1rem;
  font-weight: 400;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.rcb {
  margin: 5px;

  .label {
    min-width: 333px;
    line-height: 24px;
    font-size: 1rem;
  }
}

.text-right {
  text-align: right;
}

.mat-checkbox {
  display: flex !important;

  .mat-checkbox-layout {
    width: 100%;

    .mat-checkbox-label {
      width: 100%;
    }
  }
}

.mat-radio-button {
  display: flex !important;

  .mat-radio-label {
    width: 100%;

    .mat-radio-label-content {
      width: 100%;
    }
  }
}

.mat-checkbox-inner-container {
  margin-right: 16px !important;
}

.mat-radio-label-content {
  padding-left: 16px !important;
}

.mat-card-footer {
  margin: 16px -16px -16px !important;
}

.glabel {
  display: inline-block;
  margin: 0;

  &:after {
    display: block;
    content: '';
    border-bottom: solid 2px red;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  &.has-error:after {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }

  .error {
    color: red;
  }
}

.success-snackbar {
  background: #00af85 !important;
  color: #ffffff !important;
}

.error-snackbar {
  background: #ff0000 !important;
  color: #ffffff !important;
}

.info-snackbar {
  background: #505c6d !important;
  color: #ffffff !important;
}

.call-service-modal {
  color: white;

  .modal-header {
    border-bottom: 1px solid #e9ecef20;
  }
}

.tip {
  min-width: 56px !important;

  &-custom {
    max-width: 64px !important;
  }
}

app-navbar {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: var(--navbar-after-background-color);
  }

  & > div {
    position: relative;
    z-index: 1;
  }
}

.ui-clickable {
  cursor: pointer;
}

.primary-color {
  color: var(--primary);
}

//components
@import 'core/components/article-card';

// pages
@import 'core/pages/scanner';
@import 'core/pages/products';
@import 'core/pages/order';

//$modal-fade-transform: scale(.8);
//.modal.fade
.modal-dialog {
  position: relative;
  animation: animatebottom 0.4s;
}

@keyframes animatebottom {
  from {
    top: 5000px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.mat-dialog-container {
  border-radius: var(--border-radius-primary) !important;
}

.p-dialog {
  border-radius: var(--border-radius-primary) !important;
  overflow: auto;
}

.p-tabview .p-tabview-nav {
  background: var(--background-secondary) !important;
  border-color: var(--border-color-primary) !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: var(--background-secondary) !important;
  outline: none !important;
}

.p-tabview .p-tabview-panels {
  background: var(--background-secondary) !important;
}

.p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link {
  background: var(--background-secondary) !important;
  border-color: var(--border-color-primary) !important;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  color: var(--font-primary) !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

body .p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: var(--background-secondary);
}
