.btn-reset {
  color: var(--font-primary);
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  background: transparent;
  cursor: pointer;
  font-size: 0.85rem; //14px
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  width: 100%;
  margin-top: 30px;
  border-radius: var(--border-radius-input);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: var(--font-white);

  &:focus,
  &:hover,
  &:active {
    background: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}

.btn-basket {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  background-color: var(--secondary);
  border-color: var(--secondary);
  width: 100%;

  &:focus,
  &:hover,
  &:active {
    background: var(--secondary) !important;
    border-color: var(--secondary) !important;
  }
}

.btn-change {
  width: auto !important;
  margin-top: 0 !important;
  padding: 5px 16px;
  background-color: var(--background-primary);

  &:focus,
  &:hover,
  &:active {
    background: var(--secondary) !important;
    border-color: var(--secondary) !important;
  }
}

.btn-cancel {
  height: 42px;
  padding: 0.7rem 2.5rem;
  width: 100%;
  min-width: 150px;
  max-width: 345px;
  border-radius: var(--border-radius-input);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  color: var(--font-primary);
  font-size: 14px;
  font-weight: 400;

  &--short {
    width: fit-content;
  }

  &--visible {
    background-color: var(--background-primary);
  }
}
