@use './mixins.scss';
@use './functions.scss';

:root {
  --app-padding-width: 15px; // available only pixels, becouse used in ts file
  --app-navbar-height: calc(40px + 1rem); // where 40px - .logo height
  --app-product-title-font-size: #{functions.px-to-rem(18px)};

  @include mixins.set-var-across-min-width(
    '--app-padding-width',
    (
      0px: 15px,
      1024px: 30px,
      1366px: 70px,
      1440px: 100px,
      1600px: 150px,
    )
  );

  @include mixins.set-var-across-min-width(
    ' --app-navbar-height',
    (
      0px: calc(40px + 1rem),
      768px: calc(40px + 2rem),
    )
  );
}

// Main UI libs
// @import "~primeicons/primeicons.css";
// @import "~primeflex/primeflex.css";
// @import "primeng/primeng";

/* Override for PrimeNG Dialog Scroll Issue */
/* Corrects the blockScroll behavior in <p-dialog> by allowing background scroll. */
.p-overflow-hidden {
  overflow: unset !important;
}

//App
@import 'app/app';

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

html,
body,
#page-wrapper {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

app-payment-page,
app-order-success,
app-places {
  @media (min-width: 1300px) {
    .container {
      max-width: 1200px;
    }
  }
  @media (min-width: 1380px) {
    .container {
      max-width: 1300px;
    }
  }
  @media (min-width: 1500px) {
    .container {
      max-width: 1440px;
    }
  }
  @media (min-width: 1600px) {
    .container {
      max-width: 1500px;
    }
  }
  @media (min-width: 1800px) {
    .container {
      max-width: 1600px;
    }
  }
}

app-payment-delivery {
  .payment-delivery-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 119%;
    text-transform: uppercase;
    color: var(--font-primary);
    display: block;
    width: 100%;
    margin-bottom: 17px;
  }

  .p-field-radiobutton {
    @media screen and (max-width: 767px) {
      margin: 0;
      padding: 0;

      &:not(:last-child) {
        margin: 0;
        border-bottom: 1px solid var(--background-primary);
      }
    }
  }

  .delivery-type-card {
    border-radius: var(--border-radius-secondary);
    box-shadow: 0px 26px 37px 0px rgba(38, 40, 43, 0.06);
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 4px 15px;
    min-height: 50px;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      border-radius: unset;
    }

    .label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: unset;

      &.active {
        font-weight: 600;
      }
    }

    p-radiobutton {
      position: relative;
      top: -1px;
    }
  }

  .delivery-hour-text {
    /*padding: 0 1rem;*/
    /*font-weight: 500;*/
    font-size: 1rem;
    /*text-transform: uppercase;*/
    color: var(--font-primary);
  }
}

//

app-delivery-payment-address {
  .payment-delivery-address-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 119%;
    text-transform: uppercase;
    color: var(--font-primary);
  }

  .header {
    .payment-delivery-address-card-change-button {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .payment-delivery-address-complete-data {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 119%;
    color: var(--primary);
    cursor: pointer;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .payment-delivery-address-card-full-address {
    border-top-left-radius: var(--border-radius-secondary);
    border-top-right-radius: var(--border-radius-secondary);
    background: var(--background-secondary);
    box-shadow: 0px 26px 37px rgba(38, 40, 43, 0.06);
    min-height: 50px;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid var(--background-grey-2);
  }

  .payment-delivery-address-card-localNo {
    border-bottom-left-radius: var(--border-radius-secondary);
    border-bottom-right-radius: var(--border-radius-secondary);
    background: var(--background-secondary);
    box-shadow: 0px 26px 37px rgba(38, 40, 43, 0.06);
    min-height: 50px;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }

  .payment-delivery-address-card-content {
    font-size: 0.875rem;
    & > p {
      word-break: break-word;
    }
  }

  .font-italics {
    font-style: italic;
  }

  .payment-delivery-address-card-change-button {
    font-size: 0.75rem;
    color: var(--primary);
    padding-left: 1rem;
    text-transform: none;
    font-weight: 400;
  }
}

app-sidebar {
  .p-dialog-content {
    padding: 0;
    border-radius: var(--border-radius-primary);
    color: var(--background-secondary);
  }
}

app-product-pizza-card {
  .p-dialog .p-dialog-header {
    border-top-left-radius: var(--border-radius-primary);
    border-top-right-radius: var(--border-radius-primary);
  }

  .p-dialog-content {
    border-bottom-left-radius: var(--border-radius-primary);
    border-bottom-right-radius: var(--border-radius-primary);
    color: var(--font-primary);
  }
}

app-payment-delivery {
  .p-dialog-content {
    background-color: var(--background-secondary);
    padding: 3rem;
    border-radius: var(--border-radius-primary) !important;
  }
}

app-address-autocomplete {
  width: 100%;
}

.p-dialog {
  // transform: initial;

  .form-group {
    padding: 0 4rem;

    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .invalid-feedback {
    display: none;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .save-button {
      text-transform: uppercase;
      font-weight: 500;
      width: 145px;
      height: 38px;
      font-size: 0.875rem;
      color: var(--font-white);
      background: var(--primary);
      border: none;
      border-radius: var(--border-radius-input);

      &:hover {
        background-color: var(--primary);
      }
    }

    .cancel-button {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase;
      color: var(--font-primary);
      border: none;
      margin-top: 0.5rem;
      padding: 0.5rem;
      min-width: 145px;
      background-color: transparent !important;
      border-radius: var(--border-radius-input);

      &:hover {
        background: var(--background-primary);
      }
    }
  }
}

.customer-form {
  font-weight: 500;
  font-size: 0.875rem;
}

.dialog-customer-form-title {
  font-weight: 500;
  font-size: 1.125rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-dialog-promo-header {
  .p-dialog-header {
    .p-dialog-title {
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      font-size: 14px !important;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #6d7071 !important;
    }
  }
}

.invalid-input {
  border-color: var(--error-color) !important;
}

.loader {
  display: block;
  margin: 1rem auto;
  width: 0.875rem;
  height: 0.875rem;
  box-sizing: border-box;
  border: 0.1875rem solid var(--primary);
  border-bottom-color: transparent;
  border-radius: 50%;

  animation: rotation 1s linear infinite;
}

// PrimeNG
.p-element {
  // Button
  &.p-button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border-radius: var(--border-radius-input);

    &.p-button-sm {
      height: 2rem;
    }

    &:disabled.p-button-primary {
      background-color: var(--primary);
      border: var(--primary);
      opacity: 0.8;
    }

    &:enabled.p-button-primary {
      background-color: var(--primary);
      border: var(--primary);
    }
    .p-button-label {
      font-size: 14px;
    }
    .p-button-icon {
      font-size: 14px;
    }
  }
}

body .p-dropdown,
body .p-inputtext:not(.p-dropdown-label),
body .p-autocomplete-input,
body input {
  height: 42px;
  color: var(--font-primary);
  background: var(--background-secondary);
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-input) !important;
  width: 100%;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;

  &:hover,
  &:focus {
    border: 1px solid var(--border-color-primary-focus);
    box-shadow: none;
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: var(--font-placeholder);
  }

  &::-moz-placeholder {
    color: var(--font-placeholder);
  }

  &:-ms-input-placeholder {
    color: var(--font-placeholder);
  }

  &:-moz-placeholder {
    color: var(--font-placeholder);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--font-primary);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.form-group [formControlName]:focus ~ label,
.form-group [formControlName]:not(:placeholder-shown) ~ label {
  transform: translateY(calc(-100% - 1px));
}

body .p-dropdown-label {
  color: var(--font-primary);
}

body .mat-checkbox-frame {
  border-color: var(--font-primary);
}

body .p-dropdown {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: var(--border-radius-input) !important;
  display: flex;
  flex-direction: row;
  align-items: center;

  .p-dropdown-label {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: unset;
  }

  .p-dropdown-trigger {
    width: 1.5rem;
  }

  .p-dropdown-trigger-icon {
    font-size: 9px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dialog-terms {
  &__header {
    @include mixins.text-title(functions.px-to-rem(18px));
  }

  &__content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
}

.clickable-text {
  font-size: functions.px-to-rem(12px);
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.small-tooltip {
  .p-tooltip-text {
    font-size: 0.75rem;
    padding: 0.25rem 0.25rem;
  }
}

app-main-layout {
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: var(--font-primary);
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--font-primary);
    background: var(--background-primary);
  }

  .p-dropdown-panel {
    background: var(--background-secondary);
    color: var(--font-primary);
  }
}

.mat-dialog-container {
  background: var(--background-secondary);
  color: var(--font-primary);
}

p-dialog .p-dialog .p-dialog-content {
  background: var(--background-secondary);
  color: var(--font-primary);

  @media (max-width: 768px) {
    padding: 0 15px 15px 15px;
  }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--primary);
}

.cdk-global-scrollblock {
  overflow-y: inherit;
}
