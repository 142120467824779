.payment-component {
  color: var(--font-primary);
  border-bottom: 1px solid var(--background-grey-2);
  padding-bottom: 33px;
  margin-bottom: 33px;

  .payment-delivery-wrapper {
    border-bottom: 1px solid var(--background-grey-2);
    padding-bottom: 33px;
    margin-bottom: 35px;
  }
}
.payment-form-component {
  [class*='btn-'] {
    .label {
      order: 2;
    }
    .icon-close {
      order: 1;
      content: var(--close-src);
      padding-right: 22px;
    }
    .icon-plus {
      order: 1;
      content: var(--plus-src);
      padding-right: 8px;
    }
  }
}

.icon-arrow-right {
  content: var(--arrow-right-src);
}
